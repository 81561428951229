// export const firebaseKey = {
//   apiKey: "AIzaSyD9QmCktEC7TEOgsChw6eYWAW1wRdOrMsQ",
//   authDomain: "imstillalive-68312.firebaseapp.com",
//   databaseURL: "https://imstillalive-68312.firebaseio.com",
//   projectId: "imstillalive-68312",
//   storageBucket: "imstillalive-68312.appspot.com",
//   messagingSenderId: "456992647107"
// };

export const firebaseKey = {
  apiKey: "AIzaSyBHGDC5F7GzDkpY-9w3-QPT8Cue5wXbJq8",
  authDomain: "imstillalive-68312.firebaseapp.com",
  databaseURL: "https://imstillalive-68312.firebaseio.com",
  projectId: "imstillalive-68312",
  storageBucket: "imstillalive-68312.appspot.com",
  messagingSenderId: "456992647107"
};