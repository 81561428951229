import { Component, ViewChild } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from 'angularfire2/firestore';
import { Observable } from 'rxjs';
import { Router } from "@angular/router";
import { AuthService } from './core/auth.service';
import { Location } from '@angular/common';
import { MatSidenav } from '@angular/material';
import { MessagingService } from "./messaging-service/messaging.service";

import * as _ from "lodash";

export interface Person {
  name: string;
}

// Firebase App is always required and must be first
var firebase = require("firebase/app");

// Add additional services you want to use
require("firebase/auth");
require("firebase/database");
// require("firebase/firestore");
// require("firebase/messaging");
// require("firebase/functions");

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  // public collection: AngularFirestoreCollection<any>;
  // public usersObservable: Observable<any[]>;
  // public users: any[];

  message;

  @ViewChild('sidenav') sidenav: MatSidenav;

  constructor( afs: AngularFirestore,
      private router: Router,
      public authService: AuthService,
      private location : Location,
      private msgService: MessagingService) {

    // const settings = {timestampsInSnapshots: true};
    // afs.app.firestore().settings(settings);
    // this.collection = afs.collection('users');
    // this.usersObservable = this.collection.valueChanges();
    // this.usersObservable.subscribe(data => {
    //   console.log(data);
    //   this.users = data;
    // });
  }

  ngOnInit() {
    this.msgService.getPermission()
    this.msgService.receiveMessage()
    this.message = this.msgService.currentMessage
  }


  showNav(): boolean {
    const noShow = ['/login', '/register'];
    return !_.includes(noShow, this.router.url);
  }

  logout(){
    this.authService.doLogout()
    .then((res) => {
      this.sidenav.close();
      this.location.back();
    }, (error) => {
      console.log("Logout error", error);
    });
  }

}

